import React from 'react'
import { useRef, useEffect } from 'react'
import vidFile from "../video/sizzle.mp4"
import webm from "../video/sizzle.webm"


const Player = (props) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      muted
      loop
      id="bgvid" 
      className="absolute h-full object-cover object-center w-full"
    >
       <source src={webm} type="video/webm"/>
      <source src={vidFile} type="video/mp4"/>
      </video>
  )
}

export default Player